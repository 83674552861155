import { post } from 'epics/ajax';
import type { AjaxError } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { isRemoveRedundantModelsCreateRequest, isRetryMechanismTuningEnabled } from 'feature-flags';
import { cloneDeep } from 'lodash';
import { catchError, map, retryWhen } from 'rxjs/operators';
import { REQUEST_CREATE } from '@atlassian/help-center-common-component/connect-fragment/connect-fragment';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { ModelsDescriptor, ModelsResponse, ModelTypeOptions, ModelType, ModelContext } from './types';

type AnyModelTypeOptions = Partial<ModelTypeOptions>;

const retryableStatusCodes = [429, 500, 502, 503];
const isRetryableStatusCode = (status: number) => retryableStatusCodes.includes(status);
const MAX_RETRY_DELAY_MS = 30000;
const BASE_DELAY_MS = 10000;

const maxExpBackoffWithJitter = (retries: number, baseDelay: number, maxDelay: number) => {
    const exponential = Math.pow(2, retries) * baseDelay;
    return Math.min(exponential, maxDelay) + Math.floor(Math.random() * 1000);
};
const NUMBER_OF_RETRIES = 2;
const createOperationalEvent = (models: ModelType[], numberOfRetries: number, success: boolean, status?: number) => {
    return {
        action: 'fetch',
        actionSubject: 'models',
        source: 'unknownSource',
        attributes: {
            models,
            numberOfRetries,
            success,
            status,
        },
    };
};

const optionsNotAvailableInRestEndpoint = [
    'availableLanguages',
    'forgotPassword',
    'organisations',
    'portalsAndRequestTypes',
    'profileWebFragments',
    'sharedPortal',
    'timezones',
    'timezoneRegions',
    'xsrfToken',
    'portalsAndStatuses',
];

const addExtraImplicitDataRequirements = (types: ModelType[], options: AnyModelTypeOptions) => {
    const requestTypes = types.concat([]);
    // We want to clone child objects as well so we don't accidentally mutate them.
    // Not having it previously caused downstream actions to no longer have params after we deleted some propeties.
    const requestOptions = cloneDeep(options);

    if (requestTypes.includes('portal') && !requestTypes.includes('branding') && requestOptions.portal) {
        requestTypes.push('branding');
        requestOptions.branding = {
            id: requestOptions.portal.id,
        };
    }

    if (isRemoveRedundantModelsCreateRequest()) {
        // Implicitly fetch portal if portalWebFragments defined portalId for pages other than request create
        if (
            requestTypes.includes('portalWebFragments') &&
            requestOptions.portalWebFragments &&
            requestOptions.portalWebFragments.portalId &&
            !requestTypes.includes('portal') &&
            requestOptions.portalWebFragments.portalPage !== REQUEST_CREATE
        ) {
            requestTypes.push('portal');
            requestOptions.portal = {
                id: requestOptions.portalWebFragments.portalId,
            };
        }
    } else {
        // Implicitly fetch portal if portalWebFragments defined portalId.
        if (
            requestTypes.includes('portalWebFragments') &&
            requestOptions.portalWebFragments &&
            requestOptions.portalWebFragments.portalId &&
            !requestTypes.includes('portal')
        ) {
            requestTypes.push('portal');
            requestOptions.portal = {
                id: requestOptions.portalWebFragments.portalId,
            };
        }

        // Implicitly fetch request create if portalWebFragments defined portalId and requestTypeId.
        if (
            requestTypes.includes('portalWebFragments') &&
            requestOptions.portalWebFragments &&
            requestOptions.portalWebFragments.requestTypeId &&
            requestOptions.portalWebFragments.portalPage === REQUEST_CREATE &&
            !requestTypes.includes('reqCreate')
        ) {
            requestTypes.push('reqCreate');
            requestOptions.reqCreate = {
                portalId: requestOptions.portalWebFragments.portalId as number,
                id: requestOptions.portalWebFragments.requestTypeId,
            };
        }
    }

    if (requestTypes.includes('portal') && requestOptions.portal) {
        requestOptions.portalId = requestOptions.portal.id;
    }

    return {
        requestTypes,
        requestOptions,
    };
};

const adjustRequestOptions = (options: AnyModelTypeOptions): AnyModelTypeOptions => {
    const requestOptions = { ...options };

    for (const key in requestOptions) {
        if (requestOptions.hasOwnProperty(key)) {
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (requestOptions[key].portalId) {
                // The backend needs this as a blanket rule.
                // If we run into any we add it onto the request options object.
                // Last one wins.
                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                requestOptions.portalId = requestOptions[key].portalId;
            }
        }
    }
    for (const key in requestOptions) {
        if (optionsNotAvailableInRestEndpoint.includes(key)) {
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            delete requestOptions[key];
        }
    }

    return requestOptions;
};

const getTraceId = (err: AjaxError): string => {
    let traceId = '';
    try {
        const { xhr } = err;
        traceId = xhr.getResponseHeader('Atl-Traceid') || '';
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Failed to get traceId from error', e);
    }
    return traceId;
};

const getAllResponseHeaders = (err: AjaxError): string => {
    let headers = '';
    try {
        const { xhr } = err;
        headers = xhr.getAllResponseHeaders();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Failed to get headers from error', e);
    }
    return headers;
};

export const requestModelsLegacy = <TModelsDescriptor extends ModelsDescriptor>(
    modelsDescriptor: TModelsDescriptor,
    options: Pick<ModelTypeOptions, Unboxed<TModelsDescriptor>>,
    modelContext: ModelContext,
    context: string = contextPath
): Observable<ModelsResponse> => {
    const { requestOptions, requestTypes } = addExtraImplicitDataRequirements(modelsDescriptor, options);

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(
            `${context}/rest/servicedesk/1/customer/models`,
            {
                options: adjustRequestOptions(requestOptions),
                models: requestTypes,
                context: modelContext,
            },
            { 'Content-Type': 'application/json' }
        )
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map((response) => response.response)
            .catch((err: AjaxError) => {
                const traceId = getTraceId(err);
                const responseHeaders = getAllResponseHeaders(err);
                return Observable.throw(
                    err.response
                        ? {
                              traceId,
                              responseHeaders,
                              // Suppressing existing violation. Please fix this.
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                              status: err.response.status,

                              // Suppressing existing violation. Please fix this.
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                              errorMessages: err.response.errorMessages,

                              // Suppressing existing violation. Please fix this.
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                              nextActionUrl: err.response.nextActionUrl,

                              // Suppressing existing violation. Please fix this.
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                              nextActionDisplayText: err.response.nextActionDisplayText,
                          }
                        : {
                              traceId,
                              responseHeaders,
                              status: err.status,
                              errorMessages: [],
                          }
                );
            })
    );
};

const requestModelsWithRetries = <TModelsDescriptor extends ModelsDescriptor>(
    modelsDescriptor: TModelsDescriptor,
    options: Pick<ModelTypeOptions, Unboxed<TModelsDescriptor>>,
    modelContext: ModelContext,
    context: string = contextPath
): Observable<ModelsResponse> => {
    const { requestOptions, requestTypes } = addExtraImplicitDataRequirements(modelsDescriptor, options);
    let retries = 0;

    return post(
        `${context}/rest/servicedesk/1/customer/models`,
        {
            options: adjustRequestOptions(requestOptions),
            models: requestTypes,
            context: modelContext,
        },
        { 'Content-Type': 'application/json' }
    ).pipe(
        retryWhen((errors) =>
            errors.mergeMap((errorValue) => {
                retries += 1;
                const status = (errorValue as { status: number }).status;
                if (!isRetryableStatusCode(status as number) || retries > NUMBER_OF_RETRIES) {
                    return Observable.throw(errorValue);
                }
                return Observable.of(errorValue).delay(
                    maxExpBackoffWithJitter(retries, BASE_DELAY_MS, MAX_RETRY_DELAY_MS)
                );
            })
        ),
        map((response) => {
            if (retries > 0) {
                //only send event if there were retries to avoid sending too many events
                operationalEvent(createOperationalEvent(requestTypes, retries, true, undefined));
            }
            return response.response as ModelsResponse;
        }),
        catchError((err: AjaxError) => {
            operationalEvent(createOperationalEvent(requestTypes, retries, false, err.status));
            const traceId = getTraceId(err);
            const responseHeaders = getAllResponseHeaders(err);
            return new Observable<ModelsResponse>((observer) => {
                observer.error(
                    err.response
                        ? {
                              traceId,
                              responseHeaders,
                              status: (err.response as { status: number }).status,
                              errorMessages: (err.response as { errorMessages: string[] }).errorMessages,
                              nextActionUrl: (err.response as { nextActionUrl: string }).nextActionUrl,
                              nextActionDisplayText: (err.response as { nextActionDisplayText: string })
                                  .nextActionDisplayText,
                          }
                        : {
                              traceId,
                              responseHeaders,
                              status: err.status,
                              errorMessages: [],
                          }
                );
            });
        })
    );
};

export const requestModels = <TModelsDescriptor extends ModelsDescriptor>(
    modelsDescriptor: TModelsDescriptor,
    options: Pick<ModelTypeOptions, Unboxed<TModelsDescriptor>>,
    modelContext: ModelContext,
    context: string = contextPath
): Observable<ModelsResponse> => {
    if (isRetryMechanismTuningEnabled()) {
        return requestModelsWithRetries(modelsDescriptor, options, modelContext, context);
    }
    return requestModelsLegacy(modelsDescriptor, options, modelContext, context);
};
