import React from 'react';
import { Button } from '@helpCenter/view/common/analytics';
import { useIntl } from 'react-intl-next';
import UnlinkIcon from '@atlaskit/icon/glyph/unlink';
import { Box, xcss } from '@atlaskit/primitives';
import { ProjectsMappingStatus } from '../../types';
import type { JiraProjectsHelpCenterMappingStatus } from '../__generated__/projectsList_GetProjectsQuery.graphql';
import commonMessages from '../messages';
import messages from './messages';

export interface Props {
    selectedProjectsCount: number;
    selection: JiraProjectsHelpCenterMappingStatus;
    onBulkActionClick: () => void;
}
export const BulkActionToolbar = ({ selectedProjectsCount, selection, onBulkActionClick }: Props) => {
    const { formatMessage } = useIntl();
    const isVisible = selectedProjectsCount > 0;
    const toolbarAccessibilityLabelMessage = isVisible ? formatMessage(messages.toolbarAccessibilityLabel) : '';
    const buttonText =
        selection === ProjectsMappingStatus.LINKED
            ? formatMessage(commonMessages.projectsListUnlinkButtonText)
            : formatMessage(commonMessages.projectsListLinkButtonText);

    return (
        isVisible && (
            <Box xcss={bulkActionToolbarContainerStyles}>
                <Box as="span" xcss={projectsCountTextStyles} role="status">
                    {formatMessage(messages.selectedProjectsText, { count: selectedProjectsCount })}
                    <Box xcss={invisibleA11yTitleStyles} tabIndex={-1}>
                        {toolbarAccessibilityLabelMessage}
                    </Box>
                </Box>
                <Button
                    actionSubjectId="bulkActionButton"
                    onClick={onBulkActionClick}
                    iconBefore={<UnlinkIcon label={buttonText} size="small" />}
                    spacing="compact"
                >
                    {buttonText}
                </Button>
            </Box>
        )
    );
};

const bulkActionToolbarContainerStyles = xcss({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '96%',
    top: 'space.0',
    left: '4%',
    backgroundColor: 'elevation.surface',
    zIndex: 'layer',
    color: 'color.text.subtle',
    paddingBlock: 'space.050',
    paddingInline: 'space.0',
    margin: 'space.0',
});

const projectsCountTextStyles = xcss({
    paddingRight: 'space.200',
});

const invisibleA11yTitleStyles = xcss({
    opacity: 0,
    position: 'absolute',
});
