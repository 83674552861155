import React, { useCallback } from 'react';
import type { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { useIntl } from 'react-intl-next';
import Button, { LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import type { TriggerProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme';
import type {
    HelpCenterProjectMappingOperationType,
    projectsListLinkUnlinkMutation$data,
} from '../projects-list/__generated__/projectsListLinkUnlinkMutation.graphql';
import { ProjectsMappingOperations, ProjectsMappingStatus } from '../types';
import messages from './messages';

export interface Props {
    projectId: string;
    projectName: string;
    selection: string;
    isLastProject: boolean;
    isDisabled: boolean;
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
    onLinkUnlinkProjects: (
        projectIds: string[],
        operationType: HelpCenterProjectMappingOperationType
    ) => Promise<projectsListLinkUnlinkMutation$data>;
}

const LinkUnlinkProject = ({
    projectId,
    projectName,
    selection,
    isLastProject,
    isDisabled,
    onLinkUnlinkProjects,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}: Props) => {
    const { formatMessage } = useIntl();
    const [openUnlinkDialog, setOpenUnlinkDialog] = React.useState(false);
    const [isUnlinking, setIsUnlinking] = React.useState(false);
    const [isLinking, setIsLinking] = React.useState(false);
    const [isLastUnlink, setIsLastUnlink] = React.useState(false);

    const onLinkProject = useCallback(() => {
        setIsLinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.LINK)
            .then(() => {
                showSuccessFlagAction(messages.linkSuccessTitle, { projectName });
            })
            .catch(() => {
                showErrorFlagAction(messages.linkErrorTitle, messages.unlinkErrorDescription, [], { projectName });
            })
            .finally(() => {
                setIsLinking(false);
            });
    }, [onLinkUnlinkProjects, projectId, showSuccessFlagAction, showErrorFlagAction, projectName]);

    const onUnLinkProject = useCallback(() => {
        if (isLastProject) {
            setIsLastUnlink(true);
        } else {
            setOpenUnlinkDialog(true);
        }
    }, [isLastProject]);

    const onUnlinkDialogButtonClick = useCallback(() => {
        setIsUnlinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.UNLINK)
            .then(() => {
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showSuccessFlagAction(messages.unlinkSuccessTitle, {
                    value: projectName,
                });
            })
            .catch(() => {
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showErrorFlagAction(messages.unlinkErrorTitle, messages.unlinkErrorDescription);
            });
    }, [onLinkUnlinkProjects, projectId, projectName, showErrorFlagAction, showSuccessFlagAction]);

    const contentCallback = useCallback(() => {
        return <Box xcss={unlinkDialogPopupStyles}>{formatMessage(messages.lastProjectUnlink)}</Box>;
    }, [formatMessage]);

    const triggerCallback = useCallback(
        (triggerProps: TriggerProps) => {
            return (
                <LoadingButton
                    {...triggerProps}
                    appearance="link"
                    onClick={onUnLinkProject}
                    isLoading={isLinking}
                    isDisabled={isDisabled}
                >
                    {formatMessage(messages.projectsListUnlinkButtonText)}
                </LoadingButton>
            );
        },
        [formatMessage, isLinking, onUnLinkProject, isDisabled]
    );

    return (
        <>
            {selection === ProjectsMappingStatus.LINKED ? (
                <Popup
                    isOpen={isLastUnlink}
                    onClose={() => setIsLastUnlink(false)}
                    placement="bottom-end"
                    zIndex={layers.modal()}
                    content={contentCallback}
                    trigger={triggerCallback}
                />
            ) : (
                <LoadingButton appearance="link" onClick={onLinkProject} isLoading={isLinking} isDisabled={isDisabled}>
                    {formatMessage(messages.projectsListLinkButtonText)}
                </LoadingButton>
            )}
            <ModalTransition>
                {openUnlinkDialog && (
                    <Modal width="small" onClose={() => setOpenUnlinkDialog(false)}>
                        <ModalHeader>
                            <ModalTitle appearance="warning" testId="help-center-unlink-modal-title">
                                {formatMessage(messages.unlinkModalTitle)}
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>{formatMessage(messages.unlinkModalDesc, { projectName })}</ModalBody>
                        <ModalFooter>
                            <LoadingButton
                                onClick={onUnlinkDialogButtonClick}
                                isLoading={isUnlinking}
                                appearance="warning"
                                testId="help-center-card-delete-modal-delete-button"
                            >
                                {formatMessage(messages.unlinkModalConfirmButton)}
                            </LoadingButton>
                            <Button onClick={() => setOpenUnlinkDialog(false)} appearance="subtle">
                                {formatMessage(messages.unlinkModalCancelButton)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
};

const unlinkDialogPopupStyles = xcss({
    maxWidth: '230px',
    padding: 'space.200',
});

export default LinkUnlinkProject;
