import React from 'react';
import type { ComponentType } from 'react';
import { compose } from 'redux';
import { isUnifiedHelpReportingPageEnabled, isNewReportingExperienceEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import type { Route, RouteContext } from 'react-resource-router';
import { useRouter } from 'react-resource-router';
import { BannerWithPaperLayoutRoute } from 'view/layout/banner-with-paper-layout';
import { ScreenErrorBoundary } from 'view/layout/screen-error-boundary';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { RequestCreateAnonymousSuccess } from 'view/request-create-anonymous-success';
import { RequestCreatePage } from 'view/request-create-page';
import { RequestFeedback } from 'view/request-feedback';
import { Unsubscribe } from 'view/unsubscribe';
import { UserProfile } from 'view/user-profile';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';

import { AdminManagementLayout } from '@atlassian/admin-layout';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { HTTP, ScreenName } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import { withJsmAiConfigEnabled } from '@atlassian/help-center-common-component/with-jsm-ai-config';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { UnifiedHelpReportingPage } from '@atlassian/help-center-reporting';
import { HelpReportingContentGapResource } from '@atlassian/help-center-reporting/src/ui/content-gap-table/resources';
import messages from '@atlassian/help-center-reporting/src/ui/messages';
import { HelpReportingMetricTileResource } from '@atlassian/help-center-reporting/src/ui/metric-grid/resources';
export { portalReorderRoute } from 'routes/portal-reorder';

const portalRoutePrefix = '/portal/:portalId(\\d+)';

const RenderRequestCreate = () => {
    const [routerState] = useRouter();

    return (
        <AnalyticsScreen screenName={ScreenName.REQUEST_CREATE} screenId={routerState.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.REQUEST_CREATE}>
                <RequestCreatePage {...routerState} />
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

const RenderCreateAnonymousSuccess = () => {
    const [routerState] = useRouter();
    const { basePath, ...routeContext } = routerState;
    return (
        <AnalyticsScreen screenName={ScreenName.REQUEST_CREATE_ANONYMOUS_SUCCESS} screenId={routeContext.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.REQUEST_CREATE_ANONYMOUS_SUCCESS}>
                <RequestCreateAnonymousSuccess {...routeContext} />
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

export const userProfileRoute = createRouteWithLayout(
    { name: 'user-profile', path: '/user/profile', exact: true, resources: [], component: UserProfile },
    ScreenName.USER_PROFILE,
    {
        hideSearchButton: false,
        paperAppearance: 'normal',
        fadeInSearchModal: true,
    }
);
export const NotFoundPage: React.FC<WithAnalyticsEventsProps & RouteContext> = ({ createAnalyticsEvent }) => {
    createAnalyticsEvent &&
        createAnalyticsEvent({
            action: 'rendered',
            analyticsType: UI_EVENT_TYPE,
        }).fire();

    return (
        <ErrorPage
            error={{
                status: HTTP.NOT_FOUND,
                message: '',
                callToActionUrl: '',
                callToActionText: '',
            }}
        >
            {/*This is needed because the ErrorPage requires children props.*/}
            {''}
        </ErrorPage>
    );
};

const ConditionalComponent = () => {
    return isNewReportingExperienceEnabled() ? (
        <AdminManagementLayout featureName="Reporting Management">
            <UnifiedHelpReportingPage />
        </AdminManagementLayout>
    ) : (
        <BannerWithPaperLayoutRoute
            path="/reporting"
            component={UnifiedHelpReportingPage}
            layoutProps={{
                paperAppearance: 'xxwide',
                fadeInSearchModal: true,
                hideSearchButton: true,
            }}
            screenName={ScreenName.HELP_DESK_REPORTING}
        />
    );
};

const AIReporting: ComponentType = () => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: '/',
        }),
        withOnlyJiraAdmin({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        }),
        withJsmAiConfigEnabled({
            message: formatMessage(messages.aiConfigNotEnabledError),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        })
    )(() => <ConditionalComponent />);
    return <WrappedComponent />;
};

export const helpReportingRoute: Route = {
    name: 'reporting',
    path: '/reporting',
    exact: true,
    resources: [HelpReportingMetricTileResource, HelpReportingContentGapResource],
    component: isUnifiedHelpReportingPageEnabled()
        ? React.memo(AIReporting)
        : withAnalyticsContext({ componentName: 'notFoundPage' })(withAnalyticsEvents({})(NotFoundPage)),
};

export const requestCreateRoute = createRouteWithLayout(
    {
        name: 'request-create',
        path: '/portal/:portalId/create/:requestTypeId',
        exact: true,
        component: RenderRequestCreate,
        resources: [],
    },
    ScreenName.REQUEST_CREATE,
    {
        fadeInSearchModal: true,
        paperAppearance: 'xxwide',
    }
);

export const anonymousRequestSuccessRoute = createRouteWithLayout(
    {
        name: 'request-create-anonymous-success',
        path: '/portal/:portalId/create/:requestTypeId/confirm',
        exact: true,
        component: RenderCreateAnonymousSuccess,
        resources: [],
    },
    ScreenName.KNOWLEDGE_CATEGORY,
    {
        fadeInSearchModal: true,
    }
);

export const requestFeedbackRoute = createRouteWithLayout(
    {
        name: 'request-feedback',
        path: `${portalRoutePrefix}/:requestKey/feedback`,
        exact: true,
        component: RequestFeedback,
        resources: [],
    },
    ScreenName.FEEDBACK,
    {
        hideSearchButton: true,
        paperAppearance: 'note',
        fadeInSearchModal: true,
    }
);

export const unsubscribeRoute = createRouteWithLayout(
    {
        name: 'unsubscribeRoute',
        path: `${portalRoutePrefix}/:requestKey/unsubscribe`,
        exact: true,
        component: Unsubscribe,
        resources: [],
    },
    ScreenName.UNSUBSCRIBE,
    {
        hideSearchButton: true,
        paperAppearance: 'note',
        fadeInSearchModal: true,
    }
);

export const notFoundErrorRoute = createRouteWithLayout(
    {
        name: 'not-found-error-page',
        path: '(.*)',
        exact: true,
        component: withAnalyticsContext({ componentName: 'notFoundPage' })(withAnalyticsEvents({})(NotFoundPage)),
        resources: [],
    },
    ScreenName.ERROR_NOT_FOUND,
    {
        paperAppearance: 'normal',
        fadeInSearchModal: true,
    }
);
